/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
  font-family: "Medium";
  src: url("../../Font/Medium.otf") format("opentype"); /* Specify 'opentype' format */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "light";
  src: url("../../Font/light.otf") format("opentype"); /* Specify 'opentype' format */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Thin";
  src: url("../../Font/Thin.otf") format("opentype"); /* Specify 'opentype' format */
  font-weight: normal;
  font-style: normal;
}

.taglinecontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-block: 3rem;
  margin: 0%;
}
.seconderyline {
  font-size: clamp(1.5rem, 1.0909rem + 0.7273vw, 2rem);
  margin: 0%;
  font-family: "light";
  background-image: linear-gradient(104deg, #fff -14%, #8b8b8b 131%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.worktagline {
  font-size: clamp(4rem, 2.3636rem + 2.9091vw, 6rem);
  font-family: light;
  font-weight: 150;
  padding-block: 1rem;
  margin: 0%;
  background-image: linear-gradient(104deg, #fff -14%, #8b8b8b 131%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.card {
  width: 35rem;
  padding: 2rem;
  -webkit-box-shadow: 4px 8px 21px 0 rgba(0, 0, 0, 0.02),
    17px 34px 38px 0 rgba(0, 0, 0, 0.02), 38px 76px 51px 0 rgba(0, 0, 0, 0.01),
    67px 134px 60px 0 rgba(0, 0, 0, 0), 105px 210px 66px 0 rgba(0, 0, 0, 0);
  box-shadow: 4px 8px 21px 0 rgba(0, 0, 0, 0.02),
    17px 34px 38px 0 rgba(0, 0, 0, 0.02), 38px 76px 51px 0 rgba(0, 0, 0, 0.01),
    67px 134px 60px 0 rgba(0, 0, 0, 0), 105px 210px 66px 0 rgba(0, 0, 0, 0);
  border: solid 0.1rem #ebebeb;
}
.thumbnail {
  width: 35rem;
}
.thumbnsilcontsiner {
  padding: 1rem;
  background-color: #ffe6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.workcontainer {
  text-align: left;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: black;
}

.cardcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: clamp(1.7rem, -2.44rem + 7.36vw, 4rem);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-block: 5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .cardcontainer {
    gap: 1rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 599px) {
  .cardcontainer {
    gap: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0%;
  }

  .workcontainer {
    text-align: left;
    margin: 0%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .taglinecontainer {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .seconderyline {
    font-size: clamp(1rem, 0.4983rem + 2.6756vw, 1.5rem);
    margin: 0%;
  }

  .worktagline {
    font-size: clamp(2.5rem, 1.4967rem + 5.3512vw, 3.5rem);
    margin: 0%;
  }
}
