/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
  font-family: "light";
  src: url("../../Font/light.otf") format("opentype"); /* Specify 'opentype' format */
  font-weight: normal;
  font-style: normal;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0%;
}

.book {
  background-color: #2a84ff;
  padding: 0.6rem 1.6rem;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
}
.me {
  margin-block: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.avialablecontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.name {
  font-size: clamp(1.5rem, 1.0909rem + 0.7273vw, 2rem);
  font-weight: 600;
  margin: 0%;
  text-align: center;
  color: #fff;
}

.heroprofile {
  width: clamp(4rem, 2.3636rem + 2.9091vw, 6rem);
}

.info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.fullname {
  font-size: 1.2rem;
  font-family: "Medium";
  font-weight: 600;
  margin: 0%;
}

.green-dot-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.5rem; /* Adjust spacing */
  gap: 0.5rem;
}

.text {
  font-size: clamp(1rem, 0.5091rem + 0.8727vw, 1.6rem);
  text-align: center;
  color: #fff;
}

.animation-container {
  width: 1rem;
  height: 1rem;
}

.ringring {
  border: 0.1rem solid #62bd19;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
  opacity: 0;
}

.circle {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #62bd19;
  border-radius: 50%;
}

.pattern {
  height: 100vh;
  width: 100vw;
}

.container {
  padding: 2rem;
  height: 100vh;

  max-height: 1080px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  background-color: #00ffd5;
  background-image: radial-gradient(
      at 29% 74%,
      rgb(0, 0, 0) 0px,
      transparent 100%
    ),
    radial-gradient(at 31% 83%, rgb(40, 40, 40) 0px, transparent 50%),
    radial-gradient(at 77% 7%, hsla(297, 69%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 19% 18%, hsla(261, 77%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 70% 36%, hsla(10, 87%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 5% 0%, hsla(85, 72%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 59% 57%, hsla(191, 76%, 0%, 1) 0px, transparent 50%);
  background-size: 150% 150%;
  animation: my_animation 10s ease infinite;
}

@-webkit-keyframes my_animation {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes my_animation {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 80%;
}

.intro {
  width: 58%;
  font-family: "light";
  font-size: clamp(2rem, 0.2rem + 3.2vw, 3rem);
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  font-weight: 2000;
  margin: 0%;
}

.intro2 {
  background-image: linear-gradient(104deg, #fff -14%, #8b8b8b 131%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.5;
}

.btn {
  background-color: black;
  color: white;
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.copyemail {
  padding: 0.6rem 1.2rem;
  font-size: 1.2rem;
  color: #ffffff;
  border-radius: 2rem;
  border: 0.2rem solid rgb(255, 255, 255);
  background: none;
  cursor: pointer;
}

.green-dot-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.animation-container {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

.ringring {
  border: 0.1rem solid #62bd19;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
  opacity: 0;
}

.circle {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #62bd19;
  border-radius: 50%;
}

.allcontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0%;
  margin: 0%;
}

.btncontainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-block: 1rem;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .nav-links {
    display: none;
  }

  .container {
    padding: 1rem;
    height: 75vh;
  }

  .intro {
    font-size: clamp(1.7rem, 0.0946rem + 4.2809vw, 2.5rem);
    width: 80%;
  }

  .hero {
    height: 50vh;
  }

  .book {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .copyemail {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

@media only screen and (min-width: 300px) and (max-width: 599px) {
  .intro {
    font-size: clamp(1.4rem, 0.798rem + 3.2107vw, 2rem);
    width: 100%;
    text-align: left;
  }
  .hero {
    padding: 0%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 100%;
  }

  .header {
    padding: 0%;
  }

  .container {
    padding: 1.5rem;
    height: clamp(35rem, 24.9666rem + 53.5117vw, 45rem);
    width: 100%;
  }

  .book {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
    border-radius: 0%;
  }

  .copyemail {
    font-size: 0.9rem;
    padding: 0.8rem 1rem;
    border-radius: 0%;
  }

  .heroprofile {
    width: clamp(3.5rem, 2.9983rem + 2.6756vw, 4rem);
  }

  .me {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 1rem;
  }

  .name {
    font-size: 1.2rem;
    text-align: left;
  }

  .text {
    font-size: 1rem;
    text-align: left;
  }

  .avialablecontainer {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }
}
