.App {
  width: 100%;
  text-align: center;
  margin: 0%;
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
  box-sizing: border-box;
  background-color: rgb(0, 0, 0);
}

.appcontainer {
  max-width: 1500px;
  scroll-behavior: smooth;
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  background-color: rgb(0, 0, 0);
}
